<template>
  <StdPageContainer type="list">
    <template slot="title">
      <!-- 标题栏 -->
      <StdPageTitle title="流量充值记录"></StdPageTitle>
    </template>
    <!-- 内容区 -->
    <template slot="content">
      <div class="search_box">
        <vh-date-picker
          round
          v-model="searchForm.date"
          value-format="yyyy-MM-dd"
          type="daterange"
          @change="handleCurrentChange(1)"
          range-separator="至"
          start-placeholder="开始日期"
          prefix-icon="iconfont-v3 saasicon_date"
          end-placeholder="结束日期"
          style="width: 240px; margin-right: 16px"
          popper-class="watch-duration-popper"
        ></vh-date-picker>
        <vh-button round @click="exportList">导出</vh-button>
      </div>
      <vh-table :data="list">
        <vh-table-column label="充值方式">
          <template slot-scope="scope">
            <span>{{ scope.row.dist_type }}</span>
          </template>
        </vh-table-column>
        <vh-table-column label="充值流量" prop="flow">
          <template slot-scope="scope">
            <span>{{ scope.row.recharge_flow | unitCovert }} G</span>
          </template>
        </vh-table-column>
        <vh-table-column label="剩余流量" prop="flow">
          <template slot-scope="scope">
            <span>{{ scope.row.flow | unitCovert }} G</span>
          </template>
        </vh-table-column>
        <vh-table-column label="充值时间" prop="created_at"></vh-table-column>
        <template slot="empty">
          <StdNullPage text="暂未搜索到您想要的内容" :nullType="'search'"></StdNullPage>
        </template>
      </vh-table>
      <StdPagination
        :total="total || 0"
        :page-size="searchForm.limit"
        :currentPage="searchForm.pageNo"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </template>
  </StdPageContainer>
</template>
<script>
  import { StdPageContainer, StdPageTitle, StdNullPage, StdPagination } from '@vhcl/ui-console';
  export default {
    name: 'DataRecord',
    components: {
      StdPageContainer,
      StdPageTitle,
      StdNullPage
    },
    data() {
      return {
        list: [],
        total: 0,
        searchForm: {
          date: [],
          limit: 10,
          pageNo: 1
        }
        // distByMap: {
        //   DIST_BY_CONSOLE: '平台分配充值',
        //   DIST_BY_API: 'API分配充值',
        //   DIST_BY_ADMIN: '后台分配充值'
        // }
      };
    },
    created() {
      this.defaultSearchDate();
      this.handleCurrentChange(1);
    },
    methods: {
      defaultSearchDate() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        this.searchForm.date = [dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD')];
      },
      handleCurrentChange(page) {
        this.searchForm.pageNo = page;
        this.getTableList();
      },
      handleSizeChange(limit) {
        this.searchForm.limit = limit;
        this.searchForm.pageNo = 1;
        this.getTableList();
      },
      getTableList() {
        const params = {
          limit: this.searchForm.limit,
          pos: (this.searchForm.pageNo - 1) * this.searchForm.limit,
          user_id: this.$route.query.id,
          start_time: this.searchForm.date[0],
          end_time: this.searchForm.date[1]
        };
        this.$fetch('getChildResourceList', params).then(res => {
          if (res.code == 200) {
            this.list = res.data.list;
            this.total = res.data.total;
          }
        });
      },
      exportList() {
        const params = {
          limit: this.searchForm.limit,
          pos: (this.searchForm.pageNo - 1) * this.searchForm.limit,
          user_id: this.$route.query.id,
          start_time: this.searchForm.date[0],
          end_time: this.searchForm.date[1]
        };
        this.$fetch('exportChildResourceList', params).then(res => {
          if (res.code == 200) {
            this.$vhMessage({
              message: `流量充值记录导出成功，${
                this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
              }`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
          }
        });
      }
    }
  };
</script>
<style lang="less">
  .search_box {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
